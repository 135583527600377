$spinner_diameter: 16px;

.status-card-status-indicator {
    @extend .m_text-align--center;
    @extend .m_padding__top--small;
    @extend .m_padding__bottom--small;

    display: flex;
    justify-content: center;

    background-color: $color__card-secondary-area-background;
    border-radius: 2px;
    font-weight: $font__regular;
    font-size: $font__size-4;

    &__content {
        display: flex;
        align-items: center;
    }
    
    &__status-icon {
        @extend .m_padding__top--xx-small;
        @extend .m_margin__right--x-small;
        font-size: $glyph__size--medium;
    }    

    &__circle-spinner,
    &__circle-spinner > div {
        position: relative;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }
    &__circle-spinner {
        width: $spinner_diameter;
        height: $spinner_diameter;
        display: block;
        font-size: 0;
        color: $color__text-default;
    }
    &__circle-spinner > div {
        width: $spinner_diameter;
        height: $spinner_diameter;
        display: inline-block;
        float: none;
        border: 0 solid currentColor;
        background: transparent;
        border-width: 1px;
        border-bottom-color: transparent;
        border-radius: 100%;
        -webkit-animation: circle-spinner .75s linear infinite;
           -moz-animation: circle-spinner .75s linear infinite;
             -o-animation: circle-spinner .75s linear infinite;
                animation: circle-spinner .75s linear infinite;
    }    
}

.unknown-status {
    background-color: $color__gray-3;
    color: $color__text-default;
}

.operational {
    background-color: $color__text-green;
}

.degraded-performance {
    background-color: $color__caution;
    color: $color__text-default;
}

.partial-outage {
    background-color: $color__warning;
    color: $color__text-default;
}

.major-outage {
    background-color: $color__error;
}

.maintenance {
    background-color: $color__text-link;
}

@-webkit-keyframes circle-spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@-moz-keyframes circle-spinner {
    0% {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }
    50% {
        -moz-transform: rotate(180deg);
             transform: rotate(180deg);
    }
    100% {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}
@-o-keyframes circle-spinner {
    0% {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }
    50% {
        -o-transform: rotate(180deg);
           transform: rotate(180deg);
    }
    100% {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}
@keyframes circle-spinner {
    0% {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
           -moz-transform: rotate(180deg);
             -o-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}