body {
  margin: 0;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: start;
  text-indent: 0;
  text-transform: none;
  text-shadow: none;
  vertical-align: baseline;
  color: $color__text-default;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  background-color: $color__gray-1;
  min-height: 100vh;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  color: white;
  overflow: auto;
}

.status-page-header {
  @extend .m_text-align--center;
  display: flex;

  flex-direction: column;
  align-content: center;
  justify-content: center;

  background-color: #194c61;
  height: 250px;
  width: 100%;


  box-shadow: 1px 1px 3px #000000;

  &__header-title {
    margin-top: $spacing__x-small;
    font-size: $font__size-11;
    font-family: 'Open Sans Condensed';
    color: #FDFDFD;
    text-shadow: 1px 1px 5px #0e3c52;
    letter-spacing: 4px;
    transform: scale(0.87, 1);
    font-weight: 700;
  }

  &__logo > img {
    height: auto;
    width: 80%;
    max-width: 350px;
  }
}

.status-page-footer {
  font-size: $font__size-3;
  color: $color__text-default;
  margin-bottom: $spacing--medium;
  flex-grow: 10;
  display: flex;
  align-items: flex-end;
}

.status-page-welcome {
  @extend .m_padding__top--large;
  @extend .m_padding__bottom--large;
  font-size: $font__size-4;
  color: $color__text-default;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: space-between;

  &__welcome-message {
    width: 80%;
  }

  &--fixed-width {
    max-width: 750px;
  }

  &__contact-link a {
    text-decoration: none;
    color: $color__text-link;
    font-weight: $font__bold;
    font-size: $font__size-2;
  }

  @media screen and (max-width: 650px) {
    &__welcome-message {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    &--fixed-width {
      width: 85%;
    }
  }
}

.status-page-card-container__container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
