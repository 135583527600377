.status-page-card {
  @extend .m_text-align--center;
  @extend .m_margin__around--xx-small;
  @extend .m_padding__right--small;
  @extend .m_padding__left--small;
  @extend .m_shadow__card;
  @extend .m_border__radius--rounded;

  background-color: $color__white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  &__title {
    @extend .m_padding__top--small;
    @extend .m_padding__bottom--small;
    color: $color__text-default;
    font-size: $font__size-8;
  }

  &__footer {
    @extend .m_padding__top--small;
    @extend .m_padding__bottom--small;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $color__text-default;
    font-weight: $font__regular;
    font-size: $font__size-2;
  }

  &__footer a {
    text-decoration: none;
    color: $color__text-link;
    font-weight: $font__bold;
  }
}
