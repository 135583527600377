.status-page-card-container {
  @extend .m_text-align--center;
  @extend .m_padding__right--x-small;
  @extend .m_padding__left--x-small;
  @extend .m_padding__bottom--x-small;
  @extend .m_margin__right--large;
  @extend .m_margin__bottom--x-large;
  @extend .m_margin__left--large;
  @extend .m_shadow__card;
  @extend .m_border__radius--rounded;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: stretch;

  background-color: $color__gray-2;

  &__card-container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }

  &--fixed-width {
    width: 750px;
  }

  &__title {
    @extend .m_padding__top--x-small;
    @extend .m_padding__bottom--x-small;
    @extend .m_text-align--left;
    @extend .m_padding__left--small;

    color: $color__text-default;
    font-size: $font__size-6;
    font-weight: 900;
  }

  @media screen and (max-width: 768px) {
    &--fixed-width {
      width: 85%;
    }
  }
}

.card-container__card__item {
  @extend .m_margin__around--xx-small;
  min-width: 246px;
  flex-grow: 1;
  &:first-child:nth-last-child(n + 3),
  &:first-child:nth-last-child(n + 3) ~ & {
    flex-grow: 0;
  }
}

@media screen and (max-width: 768px) {
  .card-container__card__item {
    flex-basis: 100%;
  }
}
